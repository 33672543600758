export const groupBy = function (data, key) {
  return data.reduce(function (carry, el) {
    var group = el[key]

    if (carry[group] === undefined) {
      carry[group] = []
    }

    carry[group].push(el)
    return carry
  }, {})
}

export const groupByDate = function (data, key) {
  return data.reduce(function (carry, el) {
    var group = el[key]

    const dateTime = group.split('T')
    const date = dateTime[0]

    if (carry[date] === undefined) {
      carry[date] = []
    }

    carry[date].push(el)
    return carry
  }, {})
}
