<template>
  <section class="sub-container" ref="sub-container">
    <sub-nav-with-user-info> </sub-nav-with-user-info>
    <main class="sub">
      <div class="inner">
        <div class="tit m-tit">
          <h1>전체 알림</h1>
          <div class="btn-box">
            <button class="btn _refresh" @click="refresh">
              <span>새로고침</span>
              <i class="ico el-icon-refresh-left"></i>
            </button>
            <button
              class="btn _setting"
              @click="moveToPage('/account/push-setting')"
            >
              <span>알림설정</span>
              <i class="ico el-icon-arrow-right"></i>
            </button>
          </div>
        </div>
        <div class="sub-cont">
          <div class="alarm-wrap">
            <ul class="__list">
              <li
                v-for="messageDt in Object.keys(notificationListGroupByRegDt)"
                :key="messageDt"
              >
                <p class="_tit">
                  {{ messageDt }}
                </p>
                <div
                  v-for="message in notificationListGroupByRegDt[messageDt]"
                  :key="message.notiId"
                  class="in-text"
                  @click="moveToPage(message.link)"
                >
                  <p
                    class="message"
                    :class="{ 'new-message': message.readYn === 'N' }"
                    v-html="message.notiMessage"
                  ></p>
                  <p class="date">{{ formatDate(message.regDt) }}</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </main>
  </section>
</template>

<script>
import SubNavWithUserInfo from 'Common/SubNavWithUserInfo'
import { getNotificationList } from 'Api/modules'
import { groupByDate } from 'Utils/util'
import { mapGetters } from 'vuex'
import moment from 'moment'

export default {
  components: {
    SubNavWithUserInfo,
  },
  data() {
    return {
      notificationList: [], //{message, category, regDt}
      notificationListGroupByRegDt: {},

      loading: false,
      through: false,
      notificationListPageNumber: 0,
      notificationListNoMore: false,
    }
  },
  computed: {
    ...mapGetters(['account']),
  },
  methods: {
    async refresh() {
      await this.fetchNotificationList()
    },
    async fetchNotificationList(pageNumber = 0, pageSize = 20) {
      this.loading = true
      const { content } = await getNotificationList({
        userId: this.account.userId,
        pageNumber,
        pageSize,
      })

      if (pageNumber === 0) {
        this.notificationList = content
      } else {
        if (content.length === 0) this.notificationListNoMore = true
        this.notificationList.push(...content)
        this.loading = false
      }

      if (this.notificationList.length) {
        //날짜, 시간값 어떻게 내려오는지 확인 필요
        this.notificationListGroupByRegDt = groupByDate(
          this.notificationList,
          'regDt',
        )
        //console.error(this.notificationListGroupByRegDt)
      }
    },
    formatDate(date) {
      return moment(date).format('YYYY-MM-DD hh:mm:ss')
    },
    changeText() {},
    async onScroll({ scrollDiff, scrollTop }) {
      if (
        scrollTop < scrollDiff &&
        this.through &&
        !this.loading &&
        !this.notificationListNoMore
      ) {
        this.through = false
      }

      if (scrollTop >= scrollDiff && !this.through) {
        this.through = true
        this.fetched = false
        this.notificationListPageNumber++
        this.fetchNotificationList(this.notificationListPageNumber)
        console.info('matched', this.notificationList.length)
      }
    },
  },
  async created() {
    this.$eventBus.$on('container-scroll', this.onScroll)
    await this.fetchNotificationList()
    //문자열 자르기
    // for (var ix = 0; ix < 4; ix++) {
    //   var msgTarget = document.querySelector('.message')
    //   var msgText = msgTarget.innerText
    //   var sentenceText = msgText.split('\n')
    //   var wordText = sentenceText[ix].split(':')

    //   var tagspan = document.createElement('span')
    //   var newText = document.createTextNode(wordText[0])
    //   tagspan.appendChild(newText)
    //   var firstText = wordText[0]
    //   if (firstText == tagspan.innerText) {
    //     firstText = tagspan
    //     newText = tagspan
    //   }
    //   console.log('firstText = ', firstText)
    //   console.log('newText = ', newText)
    //   console.log('tagspan = ', tagspan)
    // }
  },
  beforeDestroy() {
    this.$eventBus.$off('container-scroll', this.onScroll)
  },
}
</script>

<style lang="scss" scoped>
.alarm-wrap > .__list > li > .in-text {
  cursor: pointer;
}
.alarm-wrap > .__list > li > .in-text > .message.new-message::before {
  border-radius: 50%;
  width: 0.3em;
  height: 0.3em;
  margin-bottom: 0.3em;
  background-color: #f74f11;
  content: '';
  display: inline-block;
}
</style>
