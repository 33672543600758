<template>
  <aside v-if="account" class="side-wrap" :class="{ open }">
    <div class="profile-box">
      <i class="icon">{{ userTypeLabel }}</i>
      <div class="pic" :style="userImg">
        <button type="button" @click="moveToPage('/account')">
          <img src="~assets/image/ico/ico-pen.svg" />
        </button>
      </div>
      <p class="user" v-if="account.companyNm">{{ account.companyNm }}</p>
      <p class="user" v-else>{{ account.userNm }}</p>
      <dl>
        <dt class="level">회원등급</dt>
        <dd>{{ account.levelNm }}</dd>
      </dl>
      <dl>
        <dt class="point">포인트</dt>
        <dd>{{ `${insertCommaToNumber(account.pointBalance)}` }}</dd>
      </dl>
      <!--dl>
        <dt class="coin">코인</dt>
        <dd>{{ account.coinBalance }}</dd>
      </!--dl-->
      <!--button class="btn" @click="moveToPage('/point')">포인트 충전하기</!--button-->
    </div>
    <slot></slot>
  </aside>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    open: {
      type: Boolean,
    },
  },
  computed: {
    ...mapGetters(['accountUserImg']),
    userImg() {
      return `background : url(${this.accountUserImg})`
    },
    userTypeLabel() {
      if (this.isEmployee) return '개인'
      else return '기업'
    },
  },
  methods: {},
}
</script>

<style lang="scss" scoped>
.side-wrap {
  background: transparent;
}

@media (max-width: 1024px) {
  .side-wrap {
    height: auto;
    z-index: 2;
  }
}
</style>
